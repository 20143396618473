import React from 'react';
import './RandomWord.css';

let words = require('./words.json');

class RandomWord extends React.Component {

    randomWord() {
        return words[Math.floor(Math.random() * words.length)];
    }

    constructor(props) {
        super(props);
        this.state = { currentWord: this.randomWord() };
        this.setWord = this.setWord.bind(this);
    }

    setWord() {
        this.setState(state => ({ currentWord: this.randomWord() }));
    }

    render() {
        return (
            <div className="random-word" onClick={this.setWord}>
                {this.state.currentWord}
            </div>
        );
    }
}

export default RandomWord;